@import url('https://fonts.googleapis.com/css2?family=Tinos&family=Hi+Melody&family=Saira:wght@200;400&display=swap');

$bg-top: rgb(30, 37, 50);
$bg-bottom: rgb(0, 0, 2);

@keyframes report-waggle {
    from { transform: rotateX(-5deg) rotateY(10deg); }
    to { transform: rotateX(-5deg) rotateY(0deg); }
}

.battle-report {
    position: relative;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: row;
    overflow: hidden;

    background: linear-gradient(to bottom, $bg-top, $bg-bottom);

    // font-family: 'Hi Melody', cursive;


    .battle-report-left {
        width: 50vw;

        .statistic {
            animation-name: report-waggle;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            animation-direction: alternate-reverse;
            animation-timing-function: cubic-bezier(.45,.05,.55,.95);
        }
    }

    color: #fff;
    font-size: 30px;
    font-family: 'Tinos', serif;
    // font-family: 'Saira', sans-serif; // TODO: Different font.
    font-weight: 400;
    word-spacing: 0.25rem;
    letter-spacing: 2px;
    text-shadow: 3px 3px rgba(0, 0, 0, 0.25);

    .huge {
        font-size: 1.75em;
    }

    &-footer {
        padding-left: 50px;
        padding-top: 20px;
    }

    &-header {
        position: relative;
        display: inline-block;

        padding-left: 50px;
        padding-top: 50px;
        margin-bottom: 50px;

        &:after {
            content: ' ';

            position: absolute;
            bottom: 0;
            left: 0;
            right: -100px;
            height: 2px;

            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0)
            );
        }
    }

    .statistic {
        margin: 30px 80px;
        font-family: 'Saira', sans-serif;
        letter-spacing: -0.9px;
        font-size: 18px;
        color: #fff;
        line-height: 1.5;
        text-shadow: 2px 3px rgba(0, 0, 0, 0.25);

        h2 {
            font-size: 1.25em;
            border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        }

        p {
            text-align: right;
            color: rgb(85, 133, 180);
        }
    }

    .battle-report-right {
        position: relative;
        height: 100vh;
        width: 50vw;
        background: url('/img/throne.jpg') no-repeat;

        -webkit-mask: linear-gradient(to right, transparent, #fff 10%);
                mask: linear-gradient(to right, transparent, #fff 10%);

        background-position: right;

        .focus-group {
            position: absolute;
            bottom: 20px;
            right: 100px;
        }
    }
}
