
.splash {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.splash__title {
    width: 100%;
    text-align: center;
    line-height: 15vh;
    color: #e6e1db;
    font-weight: 300;
    letter-spacing: 0.25em;
    font-size: 3em;

    background: #2e2c2a;
}

.logo {
    width: 80vw;
    height: 50vh;
    padding-top: 25vh;
    margin-top: -15vh;
    box-sizing: initial;
    -webkit-filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.2));
            filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.2));
}

// .logo__world--moon, .logo__world--ring {
//     fill: #e6e1db;
//     fill-opacity: 1;
//     stroke: none;
// }

// .logo__world--sky {
//     fill: #977fA6;
//     fill-opacity: 0.4;
//     stroke: none;
// }
