@import 'Utility';

$red: rgb(248, 40, 12);

.game-over {
    background: #000;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        position: relative;
        margin: 80px 0;

        // Font
        color: $red;
        font-size: 40px;
        // font-family: 'Hi Melody', cursive;
        font-family: 'Saira', sans-serif;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: -0.9px;
        text-shadow: 2px 3px rgba(0, 0, 0, 0.25);

        &:after {
            content: ' ';

            position: absolute;
            bottom: 0;
            left: -100px;
            right: -100px;
            height: 3px;

            background: linear-gradient(to right, #000, $red, #000);
        }
    }

    .corpse {
        margin-bottom: 80px;
    }

    .focus-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
