
.save-slots {
    position: relative;
    width: 800px;
    z-index: 1000;

    height: calc(100vh - 300px);
    overflow: hidden;

    flex: auto;

    padding: 8px 12px;
    margin: 10px auto;

    // Ugly scrollbar stuff
    &-scroller {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: -17px; // TODO: Adjust for cross-browser compat
        overflow-y: scroll;

        padding: 8px 16px;
    }

    .red-panel,
    .green-panel {
        opacity: 0.7;
    }
}
