
@keyframes cursor-waggle {
    from { margin-left: -60px; }
    to { margin-left: -50px; }
}

.cursor {
    position: fixed;
    margin-top: -18px;

    animation-name: cursor-waggle;
    animation-duration: 0.25s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: linear;

    // 98x64
    background: url(./cursor.png) no-repeat;
    background-size: contain;

    width: 60px;
    height: 40px;
}
