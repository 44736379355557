
@import 'Utility';

$red: rgb(248, 40, 12);
$blue: rgb(4, 3, 140);
$button-red: rgb(155, 18, 11);

.button {
    display: block;
    position: relative;

    // Reset
    background: none;
    border: 0;
    outline: none;


    text-align: center;
    font-size: 28px;
    line-height: 1.25;
    padding: 4px 30px;


    // Font
    color: rgba(255, 255, 255, 0.9);
    font-size: 30px;
    font-family: 'Saira', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.9px;
    text-shadow: 2px 3px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    border-radius: 25px;

    // &.button-theme-red {
    //     @include bevel-frame(
    //         $color: rgb(37, 23, 22),
    //         $gradient-intensity: 0
    //     );

    //     text-align: left;
    //     width: 250px;

    //     font-size: 28px;
    //     line-height: 1.25;
    //     padding: 4px 30px;

    //     border-radius: 20px;
    // }

    &-color-black {
        @include bevel-frame(
            $color: rgb(255, 0, 0),
            $gradient-intensity: 0
        );
    }

    &-color-red {
        @include bevel-frame(
            $color: rgb(94, 25, 25),
            $gradient-intensity: 0
        );
    }

    &-color-darkred {
        @include bevel-frame(
            $color: rgb(37, 23, 22),
            $gradient-intensity: 0
        );
    }

    &-color-green {
        @include bevel-frame(
            $color: rgb(0, 255, 0),
            $gradient-intensity: 0
        );
    }

    &-color-blue {
        @include bevel-frame(
            $color: rgb(0, 0, 255),
            $gradient-intensity: 0
        );
    }

    &-theme-round {
        border-radius: 20px;
    }

    &-theme-wing {
        border-radius: 20px 0px;
    }

    &-theme-outline {
        box-shadow: none;
        background: 0;

        line-height: 1;

        width: 300px;
        height: 50px;
        max-height: 50px;

        margin: 5px;

        // Left fading line
        &:before {
            content: ' ';
            position: absolute;
            top: 22px;
            left: -100px;
            width: 100px;
            height: 3px;

            background: linear-gradient(to right, transparent, $blue);
        }

        // Right fading line
        &:after {
            content: ' ';
            position: absolute;
            top: 22px;
            left: 100%;
            width: 100px;
            height: 3px;

            background: linear-gradient(to left, transparent, $blue);
        }

        // Focus coloring
        border: 3px solid $blue;

        &.is-focused {
            border-color: $red;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 40%, rgba($red, 0.5) 100%);

            &:before {
                background: linear-gradient(to right, transparent, $red);
            }

            &:after {
                background: linear-gradient(to left, transparent, $red);
            }
        }
    }
}
