
@keyframes waggle {
    from { transform: translateY(20px)}
    to { transform: translateY(-20px); }
}

@keyframes translate {
    to { transform: translateY(20px) translateX(-30px); }
}

.fairy {
    position: absolute;
    z-index: 9999;
    top: -10px;
    right: -10px;

    animation: translate 1.5s alternate infinite ease-in-out;

    &-dot {
        width: 3px;
        height: 3px;
        background: #f2e46d;

        border-radius: 5px;
        box-shadow:
            0 0 2px 1px #f2e46d, // Innermost hot center
            0 0 5px 5px #ffba61, // Blend to warmer color
            0 0 14px 12px #d95a33; // Warmest/largest radius/blur

        animation: waggle 1.5s alternate infinite ease-in-out;
    }
}

