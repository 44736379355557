@import url('https://fonts.googleapis.com/css2?family=Tinos&family=Hi+Melody&family=Expletus+Sans:ital,wght@1,700&family=Saira:wght@200;400&display=swap');

// Frame with a thick flat outer edge and inner line
@mixin flat-frame(
    $bg-color,
    $edge-color,
    $gradient-direction: 180deg,
    $gradient-intensity: 15%
) {
    position: relative;

    outline: none;
    border: 6px solid $edge-color;
    border-radius: 6px;
    background: linear-gradient($gradient-direction, darken($bg-color, $gradient-intensity), $bg-color);
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.25);

    &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: 6px;
        bottom: 6px;
        right: 6px;
        border: 5px solid rgba(lighten($bg-color, 50%), 0.25);
        mix-blend-mode: soft-light;
    }
}

// Frame with a fake lighting bevel around the edges and an optional inner glow
@mixin bevel-frame(
    $color,
    $gradient-direction: 180deg,
    $gradient-intensity: 8%,
    $glow-intensity: 0
) {
    border-radius: 20px;
    background-image: linear-gradient($gradient-direction, darken($color, $gradient-intensity), $color);

    box-shadow: 4px 4px 4px rgba(0,0,0,0.2), // Drop shadow
                inset 0 -4px 4px rgba(0, 0, 0, 0.5), // Bottom shadow
                inset -4px -4px 2px rgba(0, 0, 0, 0.5), // Right shadow
                inset 0px 4px 2px rgba(lighten($color, 80%), 0.4), // Top highlight
                inset -3px 0px 1px rgba($color, 1), // Right darken
                inset 2px -4px 2px rgba($color, 0.8), // Left slope (kills highlight near the far edge)
                inset 5px 4px 2px rgba(lighten($color, 60%), 0.3), // Left highlight
                inset 0 0 8px 6px rgba(lighten($color, 20%), $glow-intensity);
}

