
@import 'Utility';

$badge-red: rgb(155, 18, 11);
// $badge-green: rgb(20, 198, 111);
$badge-green: rgb(21, 148, 83);
$badge-blue: blue;

.badge {
    display: inline-block;

    height: 45px;
    border-radius: 23px;

    font-size: 45px;
    line-height: 0.8;
    color: #fff;
    text-align: center;
    text-shadow: 3px 3px rgba(0, 0, 0, 0.4);

    &-color-red {
        @include bevel-frame($badge-red, $glow-intensity: 1);
    }

    &-color-green {
        @include bevel-frame($badge-green, $glow-intensity: 0.8);
    }

    &-color-blue {
        @include bevel-frame($badge-blue, $glow-intensity: 1);
    }
}
