
@import 'Utility';

$yellow: rgb(250, 141, 14);
$deep-red: rgb(97, 11, 5);
$text-yellow: rgb(219, 132, 17);
$button-red: rgb(155, 18, 11);

$bad-red: rgb(71, 22, 22);
$mickey-red: rgb(199, 29, 29);

@mixin menu-theme($text-color, $frame-color, $frame-border-color) {
    color: $text-color;

    // Header content
    .menu-header {
        height: 130px;
        display: flex;
        flex-direction: row;

        &-left {
            @include flat-frame($frame-color, $frame-border-color, 180deg);
            min-width: 200px;
            margin-left: -20px;
            margin-top: -20px;
        }
        &-right {
            @include flat-frame($frame-color, $frame-border-color, 180deg);

            width: 100%;
            margin-left: 10px;
            margin-right: -20px;
            margin-top: -20px;
        }

        &-controls {
            margin-top: 20px;
            margin-left: -80px;
        }

        &-title {
            color: #fff;
            display: inline-block;
            width: 260px;
        }

        &-button {
            margin-top: 5px;
            margin-left: 40px;
            width: 250px;
            text-align: left;
        }
    }

    // Footer content
    .menu-footer {
        height: 150px;
        display: flex;
        flex-direction: row;
        margin-top: auto;

        &-left {
            @include flat-frame($frame-color, $frame-border-color, 0);
            min-width: 450px;
            margin-left: -20px;
            margin-bottom: -20px;
        }
        &-right {
            @include flat-frame($frame-color, $frame-border-color, 0);

            width: 100%;
            margin-left: 10px;
            margin-right: -20px;
            margin-bottom: -20px;
            padding: 1rem 2rem;
        }
    }
}

.menu {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    // Font setup
    font-size: 24px;
    font-family: 'Saira', sans-serif;
    font-weight: 400;
    word-spacing: 0.25rem;
    letter-spacing: 2px;
    text-shadow: 3px 3px rgba(0, 0, 0, 0.5);

    // TODO: Image as well
    background:
        repeating-linear-gradient(0deg,
            rgba(255, 255, 255, 0.05),
            rgba(255, 255, 255, 0.05) 3px,
            rgba(0, 0, 0, 0.05) 3px,
            rgba(0, 0, 0, 0.05) 6px
        ),
        linear-gradient(90deg, #000, #fff);


    &-mickey {
        position: absolute;
        width: 110px;
        height: 122px;
        bottom: -30px;
        right: 30px;
        border-radius: 50%;
        background: $mickey-red;
        mix-blend-mode: soft-light;

        // Left ear
        &:before {
            content: '';
            position: absolute;
            width: 70px;
            height: 77px;
            bottom: 92px;
            right: 71px;
            border-radius: 50%;
            background: $mickey-red;
            transform: rotateZ(38deg);
        }

        // Right ear
        &:after {
            content: '';
            position: absolute;
            width: 70px;
            height: 77px;
            bottom: 92px;
            left: 71px;
            border-radius: 50%;
            background: $mickey-red;
            transform: rotateZ(-38deg);
        }
    }
}

// Themes
.menu.menu-color-red {
    @include menu-theme(
        $text-yellow,
        $deep-red,
        $yellow
    );
}

.menu.menu-color-green {
    @include menu-theme(
        $text-yellow,
        rgb(20, 85, 54),
        rgb(20, 198, 111)
    );
}

// Generic background panels
.red-panel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    @include bevel-frame($color: rgb(107, 9, 9), $gradient-intensity: 15%);
    opacity: 0.8;
}

.green-panel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    @include bevel-frame($color: rgb(27, 68, 49));
    opacity: 0.8;
}

.title-font {
    display: inline-block;
    // TODO: Better font... We abuse a bunch of scaling / deformations
    // to make it look closer to the original font, without having it.
    font-family: 'Expletus Sans', cursive;
    font-weight: 900;
    letter-spacing: -4px;
    font-style: italic;
    line-height: 1;

    // Abusing scale here to deform the font even more
    transform: scaleX(1.25) scaleY(0.6) skew(-10deg);
}
